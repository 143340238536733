import { render, staticRenderFns } from "./ConnectToCalendarButton.vue?vue&type=template&id=49e956a1&scoped=true&"
import script from "./ConnectToCalendarButton.vue?vue&type=script&lang=ts&"
export * from "./ConnectToCalendarButton.vue?vue&type=script&lang=ts&"
import style0 from "./ConnectToCalendarButton.vue?vue&type=style&index=0&id=49e956a1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e956a1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
